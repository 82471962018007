export default class FullscreenOverlay {
    constructor({ element, options = {} }) {
        this.el = element;
        this.options = options;
        this.scrollPosition = null;

        const id = this.el.getAttribute('data-fullscreen-overlay-id');
        const openTriggers = document.querySelectorAll(`[data-fullscreen-overlay-open="${id}"]`);
        const closeTriggers = document.querySelectorAll(`[data-fullscreen-overlay-close="${id}"]`);

        this._handleClose = this._handleClose.bind(this);
        this._handleOpen = this._handleOpen.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);

        for (let trigger of openTriggers) {
            trigger.addEventListener('click', this._handleOpen);
        }

        for (let trigger of closeTriggers) {
            trigger.addEventListener('click', this._handleClose);
        }
    }

    _handleClose(e) {
        e.preventDefault();

        this._close();
    }

    _handleKeyDown(e) {
        // ESC key
        if (e.keyCode === 27) {
            this._close();
        }
    }

    _handleOpen(e) {
        e.preventDefault();

        this._open();
    }

    _close() {
        document.body.removeEventListener('keydown', this._handleKeyDown, { passive: true });

        this.el.classList.remove('fullscreen-overlay--visible');

        setTimeout(() => {
            this.el.removeAttribute('data-fullscreen-overlay-active');
            document.body.removeAttribute('data-disable-scroll');
        }, 300);

        if (this.options.onClose) {
            this.options.onClose();
        }

        if (this.options.fixedViewport) {
            document.body.removeAttribute('data-fixed-viewport-active');
            this.setBodyScrollTop(this.scrollPosition);
        }
    }

    _open() {
        document.body.addEventListener('keydown', this._handleKeyDown, { passive: true });

        this.el.setAttribute('data-fullscreen-overlay-active', true);
        document.body.setAttribute('data-disable-scroll', true);

        setTimeout(() => {
            this.el.classList.add('fullscreen-overlay--visible');
        }, 0);

        // For some reason in chrome the modal doesn't scroll when the content is too long. Other browsers are fine.
        // It was causing problems in the /categories page where we're using modal to show the details of category
        this.hackyWayOfForcingRedraw();

        if (this.options.onOpen) {
            this.options.onOpen();
        }

        if (this.options.fixedViewport) {
            this.scrollPosition = this.getBodyScrollTop();
            document.body.setAttribute('data-fixed-viewport-active', true);
        }
    }

    getBodyScrollTop() {
        const el = document.scrollingElement || document.documentElement;

        return el.scrollTop;
    }

    setBodyScrollTop(scrollTop) {
        const el = document.scrollingElement || document.documentElement;

        el.scrollTop = scrollTop;

        return el.scrollTop;
    }

    // If there's a better way of forcing draws in chrome I would love to know!
    hackyWayOfForcingRedraw() {
        if (!this.el.children.length) {
            return;
        }
        const content = this.el.children[0];
        content.style.border = 'solid 1px transparent';

        setTimeout(() => {
            content.style.border = 'solid 0px transparent';
        }, 50);
    }

    cleanup() {
        // You need to do a cleanup if FullscreenOverlay is initialised multiple times
        const id = this.el.getAttribute('data-fullscreen-overlay-id');
        const openTriggers = document.querySelectorAll(`[data-fullscreen-overlay-open="${id}"]`);
        const closeTriggers = document.querySelectorAll(`[data-fullscreen-overlay-close="${id}"]`);

        for (let trigger of openTriggers) {
            trigger.removeEventListener('click', this._handleOpen);
        }

        for (let trigger of closeTriggers) {
            trigger.removeEventListener('click', this._handleClose);
        }
    }
}
